import i18n from "i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  nb: {
    translation: {
      Choose: "Velg",
      "Log in": "Logg inn",
      "Email/username": "Brukernavn",
      Password: "Passord",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "nb",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
