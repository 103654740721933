import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "../../elements/Label/Label";
import { FormControl, Error } from "../../elements/FormControl/FormControl";
import { SelectElement } from "./Select.styles";

import { RequiredFieldIndicator } from "../../elements/RequiredFieldIndicator/RequiredFieldIndicator";

type SelectOption = {
  label: string;
  value: any;
};

type Props = {
  name?: string;
  label?: string;
  options: SelectOption[];
  error?: string;
  inputRef?: any;
  selectedValue?: string | number;
  required?: boolean;
  onSelectionChanged?: (value: any) => void;
};

export const Select: FunctionComponent<Props> = ({
  name,
  label,
  options,
  onSelectionChanged,
  error,
  inputRef,
  selectedValue,
  required,
}) => {
  const { t } = useTranslation();
  return (
    <FormControl>
      {label ? (
        <Label>
          {label}
          {required ? <RequiredFieldIndicator /> : ""}
        </Label>
      ) : null}
      <SelectElement
        name={name}
        ref={inputRef}
        value={selectedValue}
        onChange={(e) => {
          if (!onSelectionChanged) {
            return;
          }
          if (!e.target.value) {
            onSelectionChanged(null);
          } else {
            onSelectionChanged(e.target.value);
          }
        }}
        error={!!error}
      >
        <option value={null} label={t("Choose")} />

        {options.map((o) => (
          <option key={o.value} label={o.label} value={o.value} />
        ))}
      </SelectElement>
      {error ? <Error>{error}</Error> : null}
    </FormControl>
  );
};
